import './chat.scss'
import closeIcon from 'img/chat/close-icon.png'
import {useEffect, useState, useRef} from "react";
import {formatEther, parseEther} from "viem";
import {BaseError, useReadContract, useSendTransaction, useWriteContract, useEstimateGas} from "wagmi";
import {contractAbiMap, ContractAbiTypeEnum} from "src/enums/contractAbiEnum.ts";
import {networkList} from "src/hooks/network.ts";
import {networkStatus} from "src/hooks/networkStatus.ts";
import {useGlobalState} from "src/hooks/useGlobalState.ts";
import {truncateNumber} from "src/utils/utils";
import { useMessage } from "src/view/components/MessageContext.tsx";
import { ethers } from "ethers";
const cellTokenAbi = JSON.parse(contractAbiMap.get(ContractAbiTypeEnum.CELL_TOKEN) as string)
const cellToken: any = networkList[networkStatus].cellToken
const receiverAddress = networkList[networkStatus].receiverAddress
interface buyTimesProps {
    type: string,
    closeVisible: () => void,
    refresh: () => void,
}

interface PriceList {
    const: number,
    value: number,
    unit: string,
}

interface LabelList {
    label1?: string,
    label2?: string,
}
export const BuyTimes:React.FC<buyTimesProps> = ({type, closeVisible, refresh}) => {
    const { writeContractAsync } = useWriteContract();
    const { showMessage, closeMessage } = useMessage()
    const {sendTransactionAsync, data: transferHash}: any = useSendTransaction()
    const { address } = useGlobalState()
    const [celaToken, setCelaToken] = useState(0)
    const [hasCela, setHasCela] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [priceList, setPriceList] = useState<PriceList[]>([]);
    const [labelList, setLabelList] = useState<LabelList>({});
    const chatPricelist = [
        { const: 10, value: 1, unit: 'DAY' },
        { const: 20, value: 10, unit: 'DAYS' },
        { const: 30, value: 30, unit: 'DAYS' },
    ]
    const chatLabelList = {
        label1: 'You can choose from the following 3 packages to purchase additional chat credits.',
        label2: 'All payments will be used for xxx'
    }

    const getVideoPriceList = [
        { const: 5, value: 1, unit: 'video' },
        { const: 25, value: 6, unit: 'videos' },
        { const: 50, value: 20, unit: 'videos' },
    ]
    const videoLabelList = {
        label1: 'There are 3 video packages available for purchase',
        label2: 'All payments will be used for xxx'
    }
    useEffect(() => {
        if (address) {
            getCelaToken()
        }
    }, [address]);

    useEffect(() => {
        if (type === 'chat') {
            setLabelList(chatLabelList)
            setPriceList(chatPricelist)
        } else {
            setLabelList(videoLabelList)
            setPriceList(getVideoPriceList)
        }
    }, [type]);

    const getCelaToken = () => {
        refetch().then((res: any) => {
            if (res.isSuccess) {
                const num = parseFloat(formatEther(res.data))
                setCelaToken(num)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    const { refetch } = useReadContract({
        abi: cellTokenAbi,
        address: cellToken,
        functionName: 'balanceOf',
        args: [address]
    })
    const loading = useRef(false)
    const buy = () => {
        const num = priceList[currentIndex].const
        estimateGas.refetch().then((res: any) => {
            console.log(res)
            if (res.isSuccess) {
                const newGas = parseInt((parseInt(res.data.toString()) * 1.3).toString())
                writeContractAsync({
                    address: cellToken,
                    abi: cellTokenAbi,
                    functionName: "transfer",
                    args: [receiverAddress, parseEther(num.toString())],
                    gasPrice: networkStatus ? 1000001234n : 3000001234n,
                    gas: BigInt(newGas)
                })
                    .then((res: any) => {
                        closeMessage()
                        showMessage('Payment successful')
                        loading.current = false
                        closeVisible()
                        refresh()
                    })
                    .catch((error: any) => {
                        if (error) {
                            const shortMessage = (error as BaseError)?.shortMessage;
                            if (shortMessage !== 'User rejected the request.') {
                                closeMessage()
                                if (shortMessage?.includes(':')) {
                                    setTimeout(() => {
                                        showMessage(shortMessage?.split(':')[1].trim())
                                    }, 100)
                                } else {
                                    setTimeout(() => {
                                        showMessage(shortMessage)
                                    }, 100)
                                }
                            }
                            closeMessage()
                            loading.current = false
                        }
                    });
            } else {
                buy()
            }
        })
    }
    const clickBuy = () => {
        if (!loading.current) {
            loading.current = true
            const num = priceList[currentIndex].const
            showMessage('Payment in progress, please wait...', 0)

            const iface = new ethers.Interface(cellTokenAbi)
            const data: any = iface.encodeFunctionData('transfer', [receiverAddress, parseEther(num.toString())])
            gasParams.current = data
            buy()
        }
    }
    const gasParams = useRef<any | null>(null)
    const estimateGas = useEstimateGas({
        to: cellToken,
        data: gasParams.current
    })
    return(
        <div className={'buy-time-wrap'}>
            <div className={'buy-time-content'}>
                <div className={'top'}>
                    <div className={'c-006FFF label'}>Buy</div>
                    <img className={'cursor-pointer'} src={closeIcon} onClick={closeVisible} alt=""/>
                </div>
                <div className={'content'}>
                    <div className={'c-000'}>{labelList.label1}</div>
                    <div className={'c-000'}>{labelList.label2}</div>
                    <div className={'buy-method-list'}>
                        {
                            priceList.map((item, index) => (
                                <div
                                    className={`cursor-pointer single-buy-method ${currentIndex === index ? 'active-single-buy-method' : ''}`}
                                    key={index} onClick={() => setCurrentIndex(index)}>{item.const} CELA
                                    / {item.value} {item.unit}</div>
                            ))
                        }
                    </div>
                    {/*{*/}
                    {/*    celaToken !== 0 ?*/}
                    {/*        <div className={'c-000 label'}>当前账户余额：{truncateNumber(celaToken)} Cela</div>*/}
                    {/*        :*/}
                    {/*        <div className={'c-000 label'}>没有Cela 购买Cela token</div>*/}

                    {/*}*/}
                    <div className={'c-000 label'}>Current account balance: {truncateNumber(celaToken)} $CELA</div>
                    <div className={`c-FFF cursor-pointer buy-btn ${chatPricelist[currentIndex].const > truncateNumber(celaToken) ? 'btn-disabled' : '' }`} onClick={clickBuy}>
                        Buy
                    </div>
                </div>
            </div>
        </div>
    )
}