import "./Welcome.scss"
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppKit } from "@reown/appkit/react"
import { useGlobalState } from "src/hooks/useGlobalState.ts";
import { useAppKitAccount } from "@reown/appkit/react";
export const Welcome = () => {
    const [tipsVisible, setTipsVisible] = useState(false)
    const navigate = useNavigate()
    const { open } = useAppKit()
    const { setAddress, address, setSignFun } = useGlobalState()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const goToHome = () => {
        setTipsVisible(true)
        setTimeout(() => {
            navigate('/chat')
        }, 2000)
    }
    useEffect(() => {
        if (token) {
            setAddress(null)
            localStorage.removeItem('token')
            navigate('/welcome')
        }
    }, [token]);
    const { address: appAddress } = useAppKitAccount()

    const enterFun = () => {
        if (address) {
            goToHome()
        } else {
            if (appAddress) {
                setSignFun(true)
            } else {
                open()
            }
        }
    }
    return (
        <div className={'welcome-main'}>
            <div className={'title'}>
                AI Tree hole
            </div>
            <div onClick={enterFun} className={'enter'}>ENTER</div>
            {
                tipsVisible &&
                <div className={'tips-content'}>
                    Welcome to AI Tree hole<br/>
                    Successfully Connected
                </div>
            }
        </div>
    )
}