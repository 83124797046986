import "./Video.scss"
import { useGlobalState } from "src/hooks/useGlobalState.ts";
import { useEffect, useRef, useState } from "react";
import { User } from "src/api";
import { canContinuePaging } from "src/utils/utils.ts";
export const Video = () => {
    const { setVideoVisible, address, roleName, videoVisible } = useGlobalState()
    const [videoList, setVideoList] = useState([] as VideoType[])
    const pageSize = 10
    const pageNum = useRef(1)
    const [total, setTotal] = useState(0)
    const getVideoList = () => {
        const formData = {
            ethAddress: address,
            pageNum: pageNum.current,
            pageSize: pageSize
        }
        User.getVideoList(formData).then((res: any) => {
            setTotal(res.data.total)
            setVideoList(res.data.list)
        })
    }

    // paging
    const pageLoading = useRef(false)
    const handlePage = () => {
        if (!dataContainerRef.current || pageLoading.current || !canContinuePaging(pageNum.current, pageSize, total)) return;
        const {scrollTop, scrollHeight, clientHeight} = dataContainerRef.current;

        // 是否滚动到底部
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
        if (isAtBottom) {
            pageLoading.current = true
            pageNum.current++
            const formData = {
                ethAddress: address,
                pageNum: pageNum.current,
                pageSize: pageSize
            }
            User.getVideoList(formData).then((res: any) => {
                setTotal(res.data.total)
                setVideoList(prevItems => [
                    ...prevItems,
                    ...res.data.list
                ])
                pageLoading.current = false
            })
        }
    }
    const dataContainerRef = useRef<any>(null);
    useEffect(() => {
        if (dataContainerRef.current && videoList.length) {
            dataContainerRef.current.addEventListener('scroll', handlePage);
        }
    }, [videoList.length]);

    useEffect(() => {
        if (address && videoVisible) {
            pageNum.current = 1
            getVideoList()
        }
    }, [address, videoVisible]);
    return (
        <div className={'video-main'}>
            <div className={'video-header'}>
                <div className={'left'}>
                    <div className={'video-icon'}></div>
                    Video
                </div>
                <div onClick={() => setVideoVisible(false)} className={'close-icon'}></div>
            </div>
            <div className={'video-list'} ref={dataContainerRef}>
                {
                    videoList.map((item, index) => (
                        item.videoTaskStatus === 'SUCCESS' ?
                        <div key={index} className={'video-item'}>
                            <img src={item.poster ? item.poster : '#' } className={'cover'} />
                            <div className={'text-container'}>
                                <div className={'text-header'}>
                                    <div className={'title'}>
                                        {item.headline}
                                    </div>
                                    <div
                                        onClick={() => {
                                            fetch(item.videoUrl)
                                                .then((response) => response.blob())
                                                .then((blob) => {
                                                    const url = window.URL.createObjectURL(blob);
                                                    const link = document.createElement('a');
                                                    link.href = url;
                                                    link.download = 'video.mp4'; // 设置下载的文件名
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    link.remove();
                                                    window.URL.revokeObjectURL(url); // 释放 URL 对象
                                                })
                                                .catch((error) => {
                                                    console.error('Download failed:', error);
                                                });
                                        }}
                                        className={'download'}>
                                        Download
                                    </div>
                                </div>
                                <div className={'description'}>
                                    {item.subheading}
                                </div>
                            </div>
                        </div>
                            : item.videoTaskStatus === 'WAITING' || item.videoTaskStatus === 'PROCESSING' ?
                                <div key={index} className={'video-item'}>
                                    <div className={'progress-container'}>
                                        <div className={'progress-box'}>
                                            <div style={{ width: `${item.videoTaskStatus === 'WAITING' ? 30 : 70}%` }} className={'progress-block'}></div>
                                        </div>
                                        <div className={'description'}>
                                            Video generation...
                                        </div>
                                    </div>
                                </div>
                            : item.videoTaskStatus === 'FAILED' ?
                                    <div key={index} className={'video-item'}>
                                        <div className={'progress-container'}>
                                            <div className={'description error'}>
                                                {item.failReason}
                                            </div>
                                        </div>
                                    </div>
                                : null
                    ))
                }
            </div>
        </div>
    )
}