import { createAppKit } from '@reown/appkit/react'

import { WagmiProvider } from 'wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import GetRouters from "./router";
import { BrowserRouter } from "react-router-dom";
import { GlobalStateProvider } from "src/hooks/GlobalStateProvider.tsx";
import { MessageProvider } from "src/view/components/MessageContext.tsx";
import { networkStatus } from "src/hooks/networkStatus.ts";
import { bnbMainNet, bnbTestnet } from "src/hooks/network.ts";

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId from https://cloud.reown.com
const projectId = '8b3fa7c95ab1c2257bfe4a9cd850ca57'

// 2. Create a metadata object - optional
const metadata = {
    name: 'AppKit',
    description: 'AppKit Example',
    url: 'https://example.com', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/179229932']
}

// 3. Set the networks
const networks: any = [networkStatus ? bnbMainNet : bnbTestnet]

// 4. Create Wagmi Adapter
const wagmiAdapter = new WagmiAdapter({
    networks,
    projectId
})

// 5. Create modal
createAppKit({
    adapters: [wagmiAdapter],
    networks,
    projectId,
    metadata,
    features: {
        analytics: true, // Optional - defaults to your Cloud configuration
        email: false,
        socials: []
    }
})


function App() {

    return (
        <GlobalStateProvider>
            <WagmiProvider config={wagmiAdapter.wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                    <MessageProvider>
                        <BrowserRouter>
                            <GetRouters />
                        </BrowserRouter>
                    </MessageProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </GlobalStateProvider>
  )
}

export default App
