import request from '../utils/request'

const URL = {
    LOGIN: 'loginAi',
    LOGOUT: 'logoutAi',
    GET_MESSAGE: 'getMessageAi',
    GET_CHAT_HISTORY: 'ai/chatHistory',
    BUILD_VIDEO: 'ai/buildVideo',
    GET_VIDEO_LIST: 'ai/myVideoList'
}
export default {
    login(formData: any) {
        return request.post(URL.LOGIN, formData)
    },
    logout() {
        return request.post(URL.LOGOUT)
    },
    getMessage(address: any) {
        return request.get(URL.GET_MESSAGE, {
            params: {
                ethAddress: address
            }
        })
    },
    getChatHistory(formData: any) {
        return request.post(URL.GET_CHAT_HISTORY, formData)
    },
    buildVideo(formData: any) {
        return request.post(URL.BUILD_VIDEO, formData)
    },
    getVideoList(formData: any) {
        return request.post(URL.GET_VIDEO_LIST, formData)
    }
}