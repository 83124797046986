import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppKitAccount, useDisconnect } from "@reown/appkit/react"
import { useEffect, useRef, useState } from "react";
import { useGlobalState } from "src/hooks/useGlobalState.ts";
import { User } from "src/api";
import { useAccountEffect, useSignMessage } from "wagmi";
export const Layout = () => {
    const { address: appAddress } = useAppKitAccount()
    const { disconnect } = useDisconnect()
    const { address, setAddress, signFun, setSignFun } = useGlobalState()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if (appAddress) {
            setAccountAddress(appAddress)
            if (!address) {
                const token = localStorage.getItem('token')
                if (token) {
                    setAddress(appAddress)
                }
            } else {
                if (address !== appAddress) {
                    const token = localStorage.getItem('token')
                    if (token) {
                        localStorage.removeItem('token')
                        User.logout().then(() => {
                            setAddress(null)
                            setAccountAddress(appAddress)
                        })
                    }
                }
            }
        }
    }, [appAddress]);
    // login
    const [accountAddress, setAccountAddress] = useState('')

    const [messageTxt, setMessageTxt] = useState('')

    const { signMessage, data, isSuccess, isError } =useSignMessage()
    useEffect(() => {
        if (isError) {
            setSignFun(false)
        }
    }, [isError]);
    const loading = useRef(false)

    const getMessage = (address: string) => {
        if (!loading.current) {
            loading.current = true
            User.getMessage(address).then((res: any) => {
                if (res.code === 200) {
                    signMessage({message: res.data})
                    setMessageTxt(res.data)
                }
            })
        }
    }

    const loginAccount = () => {
        const formData = {
            ethAddress: appAddress,
            hash: data,
            txt: messageTxt
        }
        User.login(formData).then((res: any) => {
            if (res.code === 200) {
                localStorage.setItem('token', res.data.token)
                setAddress(accountAddress)
            }
        })
    }
    useEffect(() => {
        if (isError) {
            loading.current = false
        }
    }, [isError]);
    useEffect(() => {
        if (isSuccess) {
            loading.current = false
            loginAccount()
        }
    }, [isSuccess]);
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (accountAddress && !token) {
            getMessage(accountAddress)
        }
    }, [accountAddress]);
    useEffect(() => {
        if (signFun && accountAddress) {
            getMessage(accountAddress)
        }
    }, [signFun]);
    useAccountEffect({
        onDisconnect() {
            const token = localStorage.getItem('token')
            if (token) {
                User.logout().then(() => {
                    localStorage.removeItem('token')
                    setAddress(null)
                    navigate('/welcome')
                    setAccountAddress('')
                })
            }
        }
    })
    return (
        <div className={'layout-container'}>
            <main>
                <Outlet/>
            </main>
        </div>
    )
}