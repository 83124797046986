import { useRoutes, useNavigate } from "react-router-dom";
import * as React from "react";
import { Layout } from "src/view/layout/Layout.tsx";
import { Home } from "src/view/home/Home.tsx";
import { Welcome } from "src/view/welcome/Welcome.tsx";
import { Chat } from "src/view/chat/chat.tsx";

const RedirectToHome = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        navigate('/welcome');
    }, [navigate]);
    return null;
};
const GetRouters = () => {
    return useRoutes([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: '/',
                    element: <RedirectToHome />,
                },
                {
                    path: '/welcome',
                    element: <Welcome/>
                },
                // {
                //     path: '/home',
                //     element: <Home/>
                // },
                {
                    path: '/chat',
                    element: <Chat/>
                }
            ]
        }
    ])
}
export default GetRouters