import "./History.scss"
import VideoIcon from 'img/history/video-icon-light.png'
import { useGlobalState } from "src/hooks/useGlobalState.ts";
import { useEffect, useRef, useState } from "react";
import User from "src/api/user.ts";
import { canContinuePaging } from "src/utils/utils.ts";
import { useMessage } from "src/view/components/MessageContext.tsx";

interface historyProps {
    refresh: () => void,
}
export const History:React.FC<historyProps> = ({refresh}) => {
    const {address, roleName, historyVisible, setHistoryVisible} = useGlobalState()
    const pageSize = 10
    const pageNum = useRef(1)
    const [total, setTotal] = useState(0)
    const [charHistory, setChatHistory] = useState<ChatHistory[]>([])
    const { showMessage } = useMessage()
    const getChatHistory = () => {
        const formData = {
            ethAddress: address,
            roleName: roleName,
            pageNum: pageNum.current,
            pageSize: pageSize
        }
        User.getChatHistory(formData).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data.total)
                setChatHistory(res.data.list)
            }
        })
    }

    // paging
    const pageLoading = useRef(false)
    const handlePage = () => {
        if (!dataContainerRef.current || pageLoading.current || !canContinuePaging(pageNum.current, pageSize, total)) return;
        const {scrollTop, scrollHeight, clientHeight} = dataContainerRef.current;

        // 是否滚动到底部
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
        if (isAtBottom) {
            pageLoading.current = true
            pageNum.current++
            const formData = {
                ethAddress: address,
                roleName: roleName,
                pageNum: pageNum.current,
                pageSize: pageSize
            }
            User.getChatHistory(formData).then((res: any) => {
                if (res.code === 200) {
                    setChatHistory(prevItems => [
                        ...prevItems,
                        ...res.data.list
                    ])
                    setTotal(res.data.total)
                    pageLoading.current = false
                }
            })
        }
    }
    const dataContainerRef = useRef<HTMLDivElement>(null as any);
    useEffect(() => {
        if (dataContainerRef.current && charHistory.length) {
            dataContainerRef.current.addEventListener('scroll', handlePage);
        }
    }, [charHistory.length]);

    useEffect(() => {
        if (address && historyVisible) {
            pageNum.current = 1
            getChatHistory()
        }
    }, [address, historyVisible]);

    // create video
    const loading = useRef(false)
    const createVideo = (msgDate: string) => {
        if (!loading.current) {
            loading.current = true
            const formData = {
                ethAddress: address,
                msgDate: msgDate,
                roleName: roleName
            }
            User.buildVideo(formData).then((res: any) => {
                loading.current = false
                if (res.code === 200) {
                    showMessage('Generate Successful')
                    refresh()
                } else if (res.code === 313) {
                    showMessage('No free video generation')
                }
            })
        }
    }
    return (
        <div className={'history-main'}>
            <div className={'history-header'}>
                <div className={'left'}>
                    <div className={'clock-icon'}></div>
                    Chat History
                </div>
                <div onClick={() => setHistoryVisible(false)} className={'close-icon'}></div>
            </div>
            <div ref={dataContainerRef} className={'message-main'}>
                {
                    charHistory.map((item, index) => (
                        <div key={index} className={'message-item'}>
                            <div className={'message-header'}>
                                <div className={'time'}>
                                    {item.msgDate}
                                </div>
                                <div onClick={() => createVideo(item.msgDate)} className={'generate'}>
                                    <img className={'video-icon'} src={VideoIcon} alt=""/>
                                    Generate video
                                </div>
                            </div>
                            <div className={'text-container'}>
                                {
                                    item.recordList.map((record) => (
                                        record.senderType === 'user' ?
                                            <div key={record.msgId} className={'question'}>
                                                {record.content}
                                            </div>
                                            : record.senderType === 'assistant' ?
                                                <div key={record.msgId} className={'reply'}>
                                                    {record.content}
                                                </div>
                                                : null
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}