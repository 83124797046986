export function getStr(string: any, forward: number, later: number) {
    const strLength = string?.length
    return string ? string.substring(0, forward) + '****' + string.substring(strLength - later, strLength) : ''
}
export function truncateNumber(num: any, precision: number = 6, isString: boolean = false) {
    precision = 6
    if (num === null || num === undefined || isNaN(num) || parseFloat(num) < 0.000001) return 0;

    // Convert num to a string
    let numStr = num.toString();

    // If the number is in scientific notation, let's ensure we avoid scientific notation in the final result
    if (isString) {
        // Check if the number is in scientific notation
        if (numStr.includes('e') || numStr.includes('E')) {
            // Use toFixed or toLocaleString to convert to a readable string without scientific notation
            // using `precision || 6` to handle precision properly
            numStr = parseFloat(numStr).toFixed(precision);
        }
    }

    // Find the position of the decimal point
    const dotIndex = numStr.indexOf('.');

    if (dotIndex !== -1) { // Check if there is a decimal point
        // Get the number of digits after the decimal point
        const lengthAfterDot = numStr.length - dotIndex - 1;
        // Ensure truncation respects the precision
        const truncatedStr = numStr.substring(0, dotIndex + (precision || 6) + 1);

        // If isString is true, return as string, ensuring no scientific notation
        if (isString) {
            return truncatedStr;
        }

        return parseFloat(truncatedStr);
    }

    // If no decimal point, return the number as is (no truncation)
    return num;
}

export function canContinuePaging(currentPage: number, itemsPerPage: number, totalItems: number) {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    return currentPage < totalPages;
}

export function formatDate(timestamp: number) {
    // 创建一个新的日期对象
    const date = new Date(timestamp);

    const year = date.getFullYear().toString();

    // 获取月份，然后加一因为 getMonth() 返回的月份是从 0 开始的
    // 使用 padStart() 来确保数字是两位数的
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    // 获取日期，使用 padStart() 来确保是两位数的
    const day = date.getDate().toString().padStart(2, '0');

    // 获取小时，使用 padStart() 来确保是两位数的
    const hours = date.getHours().toString().padStart(2, '0');

    // 获取分钟，使用 padStart() 来确保是两位数的
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const seconds = date.getSeconds().toString().padStart(2, '0');

    // 将各个时间部分组合成一个字符串
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}