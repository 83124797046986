import { useState, FC, ReactNode } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

interface GlobalStateProviderProps {
    children: ReactNode;
}

export const GlobalStateProvider: FC<GlobalStateProviderProps> = ({ children }) => {
    const [historyVisible, setHistoryVisible] = useState(false);
    const [videoVisible, setVideoVisible] = useState(false);
    const [address, setAddress] = useState('');
    const [roleName, setRoleName] = useState('cz')
    const [signFun, setSignFun] = useState(false)
    return (
        <GlobalStateContext.Provider
            value={{
                historyVisible,
                setHistoryVisible,
                address,
                setAddress,
                videoVisible,
                setVideoVisible,
                roleName,
                setRoleName,
                signFun,
                setSignFun
            }}>
            {children}
        </GlobalStateContext.Provider>
    );
};