export const networkList = [
    {
        id: 97,
        chainId: '0x61',
        chainName: 'BNB Smart Chain Testnet',
        nativeCurrency: {
            name: 'tBNB',
            symbol: 'tBNB',
            decimals: 18,
        },
        rpcUrls: ['https://bsc-testnet-rpc.publicnode.com'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
        api: 'https://factoryapi.cellula.fun/',
        cellToken: '0xC55a8c3499B3981d7256bf1BAACB37a2a9966A06',
        receiverAddress: '0x2e31b91b25Dc6531C66479B2dd8ee8B81E9331dc',
    },
    {
        id: 56,
        chainId: '0x38',
        chainName: 'BNB Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://rpc.ankr.com/bsc'],
        blockExplorerUrls: ['https://bscscan.com'],
        api: 'https://factoryapi.cellula.life/',
        cellToken: '0xc790Ac3B67C42302555D928E5644B848C557E319',
        receiverAddress: '0x2e31b91b25Dc6531C66479B2dd8ee8B81E9331dc',
    },
]

export const bnbTestnet = {
    id: 97,
    name: 'BNB Smart Chain Testnet',
    nativeCurrency: { name: 'tBNB', symbol: 'tBNB', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://bsc-testnet-rpc.publicnode.com'],
        },
    },
    blockExplorers: {
        default: {
            name: 'BscScan Testnet',
            url: 'https://testnet.bscscan.com',
        },
    }
}
export const bnbMainNet = {
    id: 56,
    name: 'BNB Smart Chain',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrls: {
        default: {
            http: ['https://tame-morning-waterfall.bsc.quiknode.pro/7b8a58be397bd265138aa7bcb2ae10e4d7701f9e/'],
        },
    },
    blockExplorers: {
        default: {
            name: 'BscScan',
            url: 'https://bscscan.com',
        },
    }
}