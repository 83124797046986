import request from '../utils/request'

const URL = {
    GET_ROLE_INFO: '/ai/getRoleInfo',
    CHAT: '/ai/chat',
    CHAT_RECORDS: '/ai/chatRecords',
    GET_LAST_MSG: '/ai/getLastMsg',
    GET_USER_CHAT_INFO: '/ai/userChatInfo',
    CLEAR: '/ai/clear',
    BUILD_VIDEO_BY_CONTENT: '/ai/buildVideoByContent',
}
export default {
    // 获取机器人信息
    getRoleInfo(roleName: string) {
        return request.get(URL.GET_ROLE_INFO, {
            params: {
                roleName
            }
        })
    },
    // 聊天
    chatWithAi(chatBo : any) {
        return request.post(URL.CHAT, chatBo)
    },
    // 聊天记录
    getChatRecords(chatRecordsBo : any) {
        return request.post(URL.CHAT_RECORDS, chatRecordsBo)
    },
    // 在聊天窗口内获得最新的消息
    getLastMsg(msgId: number, ethAddress: string) {
        return request.get(URL.GET_LAST_MSG, {
            params: {
                msgId,
                ethAddress,
            }
        })
    },
    // 用户可用聊天/生视频详情
    getUserChatInfo(ethAddress: string) {
        return request.get(URL.GET_USER_CHAT_INFO, {
            params: {
                ethAddress,
            }
        })
    },
    // 清除上下文关系
    clearChat(clearChaoBo: any) {
        return request.post(URL.CLEAR, clearChaoBo)
    },
    // 聊天过程生成视频
    buildVideo(buildVideoContentBo: any) {
        return request.post(URL.BUILD_VIDEO_BY_CONTENT, buildVideoContentBo)
    },
}