import './chat.scss'
import closeIcon from 'img/chat/close-icon.png'
import {useEffect, useRef, useState} from "react";
import sendIcon from 'img/chat/send-icon.png'
import { Input } from 'antd';
const { TextArea } = Input;
interface createVideo {
    closeVisible: () => void,
    buildVideo: (share:boolean, describe: string) => void,
    buildIsSuccess: number,
}
interface ChatList {
    content?: string;
    senderType?: string;
}
export const CreateVideo:React.FC<createVideo> = ({closeVisible, buildVideo, buildIsSuccess}) => {
    const [chatContent, setChatContent] = useState<ChatList[]>([]);
    const [btnList, setBtnList] = useState([] as any);
    const [inputValue, setInputValue] = useState('')
    const [step, setStep] = useState(0);
    const loaderRef = useRef<HTMLDivElement>(null);

    const inputValueAll = useRef('')
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(e.target.value)
    };
    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 防止换行
            sendMsg();
        }
    };
    const sendMsg = async () => {
        if (step === 0 || step === 1) {
            if (!inputValue) return
            const message = inputValue.trim()
            if (message === '') return
            const currentData = [
                {
                    content: inputValue,
                    senderType: 'user',
                },
                {
                    content: 'Do you have any additional details to add?',
                    senderType: 'assistant',
                }
            ]
            setChatContent(prevChatContent => {
                const data = [...prevChatContent, ...currentData]; // 使用上一个状态
                return data;
            });
            inputValueAll.current = inputValueAll.current + inputValue + '.'
            setInputValue('')
            if (step === 0) {
                setStep(1)
            }
        }
    }

    const clickBtn = (label:string) => {
        if (label === 'No') {
            console.log(inputValueAll.current)
            const currentData = {
                content: `Please confirm if the content you're generating is [${inputValueAll.current}]. Once generated, we will help share it on some social platforms. Are you okay with that?`,
                senderType: 'assistant',
            }
            setChatContent(prevChatContent => {
                const data = [...prevChatContent, currentData]; // 使用上一个状态
                return data;
            });
            setStep(2)
        } else if (label === 'No, continue generating' || label === 'Yes, continue generating') {
            buildVideo(label === 'Yes, continue generating', inputValueAll.current)
        } else {
            return
        }
    }

    useEffect(() => {
        if (step === 1) {
            setBtnList(['Yes', 'No'])
        } else if (step === 2) {
            setBtnList(['Yes, continue generating', 'No, continue generating'])
        } else {
            setBtnList([])
        }
    }, [step])

    useEffect(() => {
        if (loaderRef.current) {
            loaderRef.current.scrollTop = loaderRef.current.scrollHeight;
        }
    }, [chatContent]);

    useEffect(() => {
        if (buildIsSuccess === 1) {
            const currentData = {
                content: `This video generation will take approximately 3 to 5 minutes. Please download it from the video module in the top-right corner after it's ready.`,
                senderType: 'assistant',
            }
            setChatContent(prevChatContent => {
                const data = [...prevChatContent, currentData]; // 使用上一个状态
                return data;
            });
            setStep(3)
        }
    }, [buildIsSuccess]);

    useEffect(() => {
        const label = [
            {
                senderType: 'system',
                content: 'Hello, my name is [xxxxx]. I enjoy [xxx] and dislike [xxxx]. I believe [xxx] is more valuable. If you can persuade me, I can help turn our conversation into a video and share it on the social media platform [x]."'
            },
            {
                senderType: 'assistant',
                content: 'Please describe the content of the video you would like to generate.'
            },
        ]
        setChatContent(label);

        // setBtnList(['有', '没有'])
    }, [])
    return (
        <div className={'create-video-wrap'}>
            <div className={'create-video-content'}>
                <div className={'top'}>
                    <div className={'c-006FFF label'}>Chat</div>
                    <img className={'cursor-pointer'} src={closeIcon} onClick={closeVisible} alt=""/>
                </div>
                <div className={'scroll-content content'} ref={loaderRef}>
                    {
                        chatContent.map((item, index) => (
                            <div className={'single-chat'} key={index}>
                                {
                                    item.senderType === 'system' ?
                                        <div className={'c-FFF600 chat-normal'}>{item.content}</div>
                                        : item.senderType === 'user' ?
                                            <div className={'chat-user'}>
                                                <div className={'c-006FFF'}>{item.content}</div>
                                            </div>
                                            :
                                            <div className={'chat-ai'}>
                                                <div className={'c-000'}>{item.content}</div>
                                            </div>
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={'bottom'}>
                    <div className={'btn-list'}>
                        {
                            btnList.map((item:any, index:number) => (
                                <div key={index} className={'single-btn cursor-pointer'} onClick={() => clickBtn(item)}>{item}</div>
                            ))
                        }
                    </div>
                    <div className={'input-wrap'}>
                        <TextArea onChange={onChange} value={inputValue} className={'custom-textarea'}
                                  placeholder="Type your message..."
                                  onKeyDown={handleKeyDown}
                                  style={{height: '100%', resize: 'none'}}/>
                        <div className={`cursor-pointer send-icon`}>
                            <img src={sendIcon} alt="" onClick={sendMsg}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}