// GlobalStateContext.tsx
import { createContext } from 'react';

interface GlobalState {
    historyVisible: boolean,
    setHistoryVisible: (value: boolean) => void
    address: string;
    setAddress: (value: string | any) => void
    videoVisible: boolean,
    setVideoVisible: (value: boolean) => void
    roleName: string
    setRoleName: (value: string) => void
    signFun: boolean
    setSignFun: (value: boolean) => void
}

export const GlobalStateContext = createContext<GlobalState | undefined>(undefined);