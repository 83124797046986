import Axios, { AxiosResponse } from 'axios'
import { networkList } from "src/hooks/network.ts";
import { networkStatus } from "src/hooks/networkStatus.ts";

// const baseURL = '/api'
const baseURL: any = networkList[networkStatus].api

const axios = Axios.create({
    baseURL,
    timeout: 50000,
    withCredentials: true
})
axios.defaults.withCredentials = true


axios.interceptors.request.use(
    (config) => {
        config.withCredentials = true
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['token'] = token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        const {status, data} = response
        if (data.code === 402) {
            window.location.href = '/welcome?token=0'
        }
        return response.data
    },
    (error) => {
        if (error.response && error.response.data) {
            const code = error.response.status
            const msg = error.response.data.message
            console.error(`Code: ${code}, Message: ${msg}`)
            console.error('[Axios Error]', error.response)
        } else {
            console.error(`${error}`)
        }
        return Promise.reject(error)
    }
)

export default axios