import './chat.scss'
import { Input } from 'antd';
import {useEffect, useState, useRef} from "react";
import {BuyTimes} from './buyTimes.tsx'
import {CreateVideo} from './createVideo.tsx'
import { ChatApi } from 'src/api'
import { useGlobalState } from "src/hooks/useGlobalState.ts";
import { Drawer } from "antd";
import {formatDate, getStr} from "src/utils/utils";
import { useDisconnect } from "@reown/appkit/react"
import { useMessage } from "src/view/components/MessageContext.tsx";

const { TextArea } = Input;
import copyIcon from 'img/chat/copy-icon.png'
import xIcon from 'img/chat/x-icon.png'
import tIcon from 'img/chat/t-icon.png'
import userIcon from 'img/chat/user-icon.png'
import moreIcon from 'img/chat/more-icon.png'
import sendIcon from 'img/chat/send-icon.png'
import loadingIcon from 'img/chat/loading-icon.png'
import userChatIcon from 'img/chat/user-chat-icon.png'
import line from 'img/chat/line.png'

import newChatIcon from 'img/chat/new-chat-icon.png'
import historyIcon from 'img/chat/history-icon.png'
import videoIcon from 'img/chat/video-icon.png'
import logoutIcon from 'img/chat/logout-icon.png'
import {History} from "src/view/history/History.tsx";
import {Video} from "src/view/video/Video.tsx";
interface ChatList {
    content?: string;
    msgId?: string,
    msgTime?: string;
    msgType?: string,
    senderType?: string;
}
interface RoleInfo {
    roleAddress?: string;
    roleImgUrl?: string;
    roleName?: string;
}

interface UserChat {
    chatExpireTime: number;
    freeChatNum: number;
    validVideoNum: number;
    videoNum: number;
}


export const Chat = () => {
    const { disconnect } = useDisconnect()
    const { showMessage, closeMessage } = useMessage()
    const { address, roleName, historyVisible, setHistoryVisible, videoVisible, setVideoVisible } = useGlobalState()
    const [roleInfo, setRoleInfo] = useState<RoleInfo>({})
    const [pageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [inputValue, setInputValue] = useState('')
    const [isPolling, setIsPolling] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const loaderRef = useRef<HTMLDivElement>(null);

    const [hasUserChat, setHasUserChat] = useState<boolean>(false);
    const [userChat, setUserChat] = useState<UserChat>({chatExpireTime: 0,
        freeChatNum: 0,
        validVideoNum: 0,
        videoNum: 0,})
    const [buyType, setBuyType] = useState('')

    const [buyTimes, setBuyTimes] = useState(false);
    const [functionListVisible, setFunctionListVisible] = useState(false);
    const [chatContent, setChatContent] = useState<ChatList[]>([]);

    const [createVideoVisible, setCreateVideoVisible] = useState<boolean>(false);

    const [buildIsSuccess, setBuildIsSuccess] = useState<number>(0);

    const functionListRef = useRef<HTMLDivElement | null>(null);
    const moreIconRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        if (address) {
            getUserChat()
        }
    }, [address]);
    useEffect(() => {
        if (roleName) {
            getRole()
        }
    }, [roleName]);

    const handleCopy = async () => {
        try {
            const text:any = roleInfo?.roleAddress
            await navigator.clipboard.writeText(text);
            showMessage('Successfully Copied')
        } catch (err) {}
    };

    const getUserChat = async () => {
        try {
            const res:any = await ChatApi.getUserChatInfo(address)
            if (res.code === 200) {
                const data = res.data
                if (!data.chatExpireTime) {
                    data.chatExpireTime = 0
                }
                setHasUserChat(true)
                setUserChat(data)
            }
        } catch (e) {}
    }


    const handleClickOutside = (event:MouseEvent) => {
        // 检查点击的元素是否在弹框内部
        if (functionListRef.current && !functionListRef.current.contains(event.target as Node) && moreIconRef.current && !moreIconRef.current.contains(event.target as Node)) {
            setFunctionListVisible(false); // 关闭弹框
        }
    };
    const getData = async () => {
        await getChatLength()
        await getRecordsList()
        if (chatContent.length < 10 && pageNum.current !== 1) {
            pageNum.current--
            await getRecordsList()
        }
    }
    const getRole = async () => {
        try {
            const res:any = await ChatApi.getRoleInfo(roleName)
            if (res.code === 200) {
                setRoleInfo(res.data)
            }
        } catch (e) {}
    }
    useEffect(() => {
        if (roleInfo.roleName) {
            getData()
            // 添加滚动监听
            const current:any = loaderRef.current;
            if (current) {
                current.addEventListener('scroll', handleScroll);
            }
        }
    }, [roleInfo]);

    const pageNum = useRef(1)
    const getChatLength = async () => {
        try {
            const params = {
                ethAddress: address,
                pageNum: 1,
                pageSize: 1,
                roleName: roleInfo.roleName
            }
            const res:any = await ChatApi.getChatRecords(params)
            if (res.code === 200) {
                setTotal(res.data.total)
                const num = Math.ceil(res.data.total / pageSize)
                pageNum.current = num === 0 ? 1 : num
            }
        } catch (e) {}
    }

    const getRecordsList = async () => {
        try {
            const params = {
                ethAddress: address,
                pageNum: pageNum.current,
                pageSize,
                roleName: roleInfo.roleName
            }
            const res:any = await ChatApi.getChatRecords(params)
            if (res.code === 200) {
                setChatContent(prevChatContent => {
                    const data = [...res.data.list, ...prevChatContent]; // 使用上一个状态
                    return data;
                });
            }
        } catch (e) {}
    }

    const isLoadingMore = useRef(false)
    const lastScrollHeight = useRef(0)

    useEffect(() => {
        if (loaderRef.current) {
            if (!isLoadingMore.current) {
                loaderRef.current.scrollTop = loaderRef.current.scrollHeight;
            } else {
                const container = loaderRef.current;
                container.scrollTop = container.scrollHeight - lastScrollHeight.current
                isLoadingMore.current = false
            }
        }
    }, [chatContent]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(e.target.value)
    };
    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 防止换行
            if (userChat.freeChatNum === 0 && userChat.chatExpireTime < Date.now()) {
                showMessage('Your free attempts have been used up. Please purchase more chat credits.')
                return
            }
            sendMsg();
        }
    };
    const msgIdNum = useRef(0)
    const sendMsg = async () => {
        if (!inputValue) return
        const message = inputValue.trim()
        if (message === '') return
        const currentData = {
            content: inputValue,
            senderType: 'user',
        }
        const data:any = [...chatContent, currentData]
        setChatContent(data)
        try {
            const params = {
                chatInfo: {
                    content: inputValue,
                    roleName: roleInfo.roleName
                },
                ethAddress: address,
                roleName: roleInfo.roleName
            }
            const res:any = await ChatApi.chatWithAi(params)
            if (res.code === 312) {
                showMessage('Your free attempts have been used up. Please purchase more chat credits.')
                return
            }
            if (res.code === 200) {
                msgIdNum.current = res.data
                setInputValue('')
                setIsPolling(true);
            }
        } catch (e) {}
    }
    const poll = async () => {
        try {
            const res:any = await ChatApi.getLastMsg(msgIdNum.current, address); // 你的请求
            if (res.code === 200) {
                const newContent = res.data.content; // 获取 content
                if (newContent) {
                    setIsPolling(false); // 停止轮询
                    setChatContent(prevChatContent => {
                        const data = [...prevChatContent, res.data]; // 使用上一个状态
                        return data;
                    });
                    if (!userChat.chatExpireTime || userChat.chatExpireTime < Date.now()) {
                        getUserChat()
                    }
                }
            }
        } catch (error) {}
    };
    useEffect(() => {
        // 开始轮询
        const intervalId = setInterval(() => {
            if (isPolling) {
                poll();
            }
        }, 1000); // 每 1 秒请求一次
        // 清理函数
        return () => {
            clearInterval(intervalId);
        };
    }, [isPolling]); // 依赖 isPolling

    const loadMoreData = async () => {
        if (isLoading || pageNum.current <= 1) return; // 防止重复加载和页码小于 1
        setIsLoading(true);
        try {
            pageNum.current--
            const params = {
                ethAddress: address,
                pageNum: pageNum.current,
                pageSize,
                roleName: roleInfo.roleName
            }
            const res:any = await ChatApi.getChatRecords(params)
            if (res.code === 200) {
                setChatContent(prevChatContent => {
                    const data = [...res.data.list, ...prevChatContent]; // 使用上一个状态
                    return data;
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleScroll = () => {
        if (loaderRef.current) {
            const { scrollTop } = loaderRef.current;
            if (scrollTop === 0) {
                lastScrollHeight.current = loaderRef.current.scrollHeight
                isLoadingMore.current = true
                loadMoreData(); // 如果滚动到顶部，加载更多数据
            }
        }
    };
    const wait = (time:any) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const loopGetConverting = async () => {
        let count = 0
        while (count < 10) {
            await getUserChat()
            await wait(5000);
            count += 1;
        }
    }
    const refreshUser = () => {
        loopGetConverting()
    }
    const clickBuy = (type:string) => {
        setBuyType(type)
        setBuyTimes(true)
    }

    const clearChatRecords = async () => {
        try {
            const params = {
                ethAddress: address,
                roleName: roleInfo.roleName
            }
            const res:any = await ChatApi.clearChat(params)
            if (res.code === 200) {
                setFunctionListVisible(false)
                const chatData = {
                    content: '------',
                    msgType: 'TXT',
                    senderType: 'system'
                }
                setChatContent(prevChatContent => {
                    const data = [...prevChatContent, chatData]; // 使用上一个状态
                    return data;
                });
            }
        } catch (e) {}
    }

    const logout = () => {
        disconnect()
    }

    const buildVideo = async (share:boolean, describe:string) => {
        try {
            const buildVideoContentBo = {
                describe,
                ethAddress: address,
                roleName: roleInfo.roleName,
                share
            }
            const res:any = await ChatApi.buildVideo(buildVideoContentBo)
            if (res.code === 200) {
                setBuildIsSuccess(1)
                loopGetConverting()
            } else if (res.code === 313) {
                setBuildIsSuccess(2)
                showMessage('Your video generation quota has been used up. Please purchase more to continue.')
            } else {
                setBuildIsSuccess(2)
                showMessage(res.message)
            }
        } catch (e) {}
    }
    useEffect(() => {
        const current:any = loaderRef.current;
        // 添加事件监听器
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            if (current) {
                current.removeEventListener('scroll', handleScroll);
            }
            // 清理事件监听器
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className={'chat-wrap'}>
            <div className={'chat-container'}>
                <div className={'top-wrap'}>
                    <div className={'left'}>
                        <div className={'c-006FFF'}>{roleInfo.roleAddress}</div>
                        <img className={'cursor-pointer'} src={copyIcon} alt="" onClick={handleCopy} />
                    </div>
                    <div className={'right'}>
                        <img className={'cursor-pointer x-icon'} src={xIcon} alt=""/>
                        <img className={'cursor-pointer t-icon'} src={tIcon} alt=""/>
                        <div className={'user-wrap'}>
                            <img className={'user-icon'} src={userIcon} alt=""/>
                            <div className={'c-006FFF user-account'}>{getStr(address, 4, 4)}</div>
                            <img className={'cursor-pointer more-icon'} ref={moreIconRef} onClick={() => setFunctionListVisible(!functionListVisible)} src={moreIcon} alt=""/>
                        </div>
                    </div>
                </div>
                {
                    functionListVisible &&
                    <div className={'function-list'} ref={functionListRef}>
                        <div className={'user-content'}>
                            <img src={userIcon} alt=""/>
                            <div className={'c-006FFF'}>{getStr(address, 4, 4)}</div>
                        </div>
                        <div className={'des'}>
                            {
                                userChat.chatExpireTime !== 0 &&
                                <div className={'c-006FFF'}>{formatDate(userChat.chatExpireTime)} Expiration</div>
                            }
                            <div className={'c-006FFF'}>Video Generation Count：{userChat.videoNum}</div>
                        </div>
                        <div className={'list'}>
                            <div className={'cursor-pointer list-item'} onClick={clearChatRecords}>
                                <img src={newChatIcon} alt=""/>
                                <div className={'c-006FFF'}>New Chat</div>
                            </div>
                            <div className={'cursor-pointer list-item'} onClick={() => setHistoryVisible(true)}>
                                <img src={historyIcon} alt=""/>
                                <div className={'c-006FFF'}>History</div>
                            </div>
                            <div className={'cursor-pointer list-item'} onClick={() => setVideoVisible(true)}>
                                <img src={videoIcon} alt=""/>
                                <div className={'c-006FFF'}>Video</div>
                            </div>
                        </div>
                        <div className={'cursor-pointer list-item logout-content'} onClick={logout}>
                            <img src={logoutIcon} alt=""/>
                            <div className={'c-006FFF'}>Log out</div>
                        </div>
                    </div>
                }
                <div className={'scroll-content content'} ref={loaderRef}>
                    {
                        chatContent.map((item, index) => (
                            <div className={'single-chat'} key={index}>
                                {
                                    item.senderType === 'system' ?
                                        <div>
                                            {
                                                item.content === '------' ?
                                                    <div className={'chat-line'}>
                                                        <img src={line} alt=""/>
                                                        <div className={'c-FFF chat-line-label'}>New Chat</div>
                                                        <img src={line} alt=""/>
                                                    </div>
                                                    :
                                                    <div className={'c-FFF600 chat-normal'}>{item.content}</div>
                                            }
                                        </div>
                                        : item.senderType === 'user' ?
                                            <div className={'chat-user'}>
                                                <img src={userChatIcon} alt=""/>
                                                <div className={'c-006FFF'}>{item.content}</div>
                                            </div>
                                            :
                                            <div className={'chat-ai'}>
                                                <div className={'c-0EF icon'}>$</div>
                                                <div className={'c-0EF'}>{item.content}</div>
                                            </div>
                                }
                            </div>
                        ))
                    }
                    <div className={'c-000 cursor-pointer create-video'} onClick={() => {setCreateVideoVisible(true)}}>Help me create a video</div>
                </div>
                {
                    hasUserChat &&
                    <div className={'tips-wrap'}>
                        {
                            userChat.freeChatNum > 0 ?
                                <div className={'c-FFF ellipsis tips'}>You have {userChat.freeChatNum} free conversations remaining.</div>
                                :
                                <div className={'tips-buy-wrap'}>
                                    {
                                        (!userChat.chatExpireTime || userChat.chatExpireTime < Date.now()) &&
                                        <div className={'tips-buy'}>
                                            <div className={'c-3A2800 ellipsis label'}>Your free attempts have been used up. Please purchase more chat credits.</div>
                                            <div className={'c-00021B cursor-pointer buy-btn'}
                                                 onClick={() => clickBuy('chat')}>Buy
                                            </div>
                                        </div>
                                    }
                                </div>


                        }
                        {
                            userChat.validVideoNum > 0 ?
                                <div className={'c-FFF ellipsis tips'}>You have {userChat.validVideoNum} opportunities for create
                                    video.</div>
                                :
                                <div className={'tips-buy-wrap'}>
                                    <div className={'tips-buy'}>
                                        <div
                                            className={'c-3A2800 ellipsis label'}>Your video generation quota has been used up. Please purchase more to continue.
                                        </div>
                                        <div className={'c-00021B cursor-pointer buy-btn'}
                                             onClick={() => clickBuy('video')}>Buy
                                        </div>
                                    </div>
                                </div>


                        }
                    </div>
                }
                <div className={'bottom-wrap'}>
                    <TextArea onChange={onChange} value={inputValue} className={'custom-textarea'}
                              placeholder="Type your message..."
                              onKeyDown={handleKeyDown}
                              style={{height: '60%', resize: 'none'}}/>
                    <div className={`cursor-pointer send-icon ${userChat.freeChatNum === 0 && userChat.chatExpireTime < Date.now() ? 'btn-disabled' : ''}`}>
                        {
                            isPolling ?
                                <img className={'loading-icon'} src={loadingIcon} alt=""/>
                                :
                                <img src={sendIcon} alt="" onClick={sendMsg}/>
                        }
                    </div>
                </div>
            </div>

            {
                buyTimes &&
                <BuyTimes type={buyType} closeVisible={() => setBuyTimes(false)} refresh={refreshUser}></BuyTimes>
            }
            {
                createVideoVisible &&
                <CreateVideo closeVisible={() => setCreateVideoVisible(false)} buildVideo={buildVideo} buildIsSuccess={buildIsSuccess}></CreateVideo>
            }
            <Drawer width={'auto'} className={'history-drawer'} closeIcon={false} onClose={() => setHistoryVisible(false)} open={historyVisible}>
                <History refresh={refreshUser}></History>
            </Drawer>
            <Drawer width={'auto'} className={'history-drawer'} closeIcon={false} onClose={() => setVideoVisible(false)} open={videoVisible}>
                <Video/>
            </Drawer>
        </div>
    )
}